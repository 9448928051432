import React from 'react';
import { Box, Typography, Button, Card, CardContent, CardActions } from '@mui/material';
import { Link } from 'react-router-dom';
import { Info as InfoIcon, ContactSupport as SupportIcon, Feedback as FeedbackIcon, OpenInNew as OpenInNewIcon } from '@mui/icons-material';
import TitleSection from './TittleTheme';
import { camara } from '../adm/admin/firebaseConfig';

function InfoCards({config}) {
    const cards = [
        {
            title: 'E-SIC',
            description: 'É o Sistema Eletrônico do Serviço de Informações ao Cidadão, facilitando o envio de pedidos de informação para acesso a dados públicos.',
            icon: <InfoIcon sx={{ fontSize: 40, color: config?.color }} />,
            link: '/e-sic'
        },
        {
            title: 'Carta de Serviço',
            description: 'Documento que descreve serviços públicos, prazos e normas, oferecendo ao cidadão informações claras e objetivas sobre os serviços disponíveis.',
            icon: <SupportIcon sx={{ fontSize: 40, color: config?.color }} />,
            link: '/prefeitura/carta-de-servico'
        },
        {
            title: 'Ouvidoria',
            description: 'Canal de comunicação para enviar sugestões, reclamações e elogios, visando garantir que as opiniões e necessidades dos cidadãos sejam ouvidas.',
            icon: <FeedbackIcon sx={{ fontSize: 40, color: config?.color }} />,
            link: '/ouvidoria'
        },
        {
            title: 'Transparência Fiscal',
            description: 'Ferramenta que permite ao cidadão acompanhar receitas e despesas públicas, promovendo clareza sobre a gestão dos recursos financeiros do governo.',
            icon: <InfoIcon sx={{ fontSize: 40, color: config?.color }} />,
            link: '/transparencia'
        }

    ];

    const cardCamara = [
        {
            title: 'E-SIC',
            description: 'É o Sistema Eletrônico do Serviço de Informações ao Cidadão, facilitando o envio de pedidos de informação para acesso a dados públicos.',
            icon: <InfoIcon sx={{ fontSize: 40, color: config?.color }} />,
            link: '/e-sic'
        },
        {
            title: 'Ouvidoria',
            description: 'Canal de comunicação para enviar sugestões, reclamações e elogios, visando garantir que as opiniões e necessidades dos cidadãos sejam ouvidas.',
            icon: <FeedbackIcon sx={{ fontSize: 40, color: config?.color }} />,
            link: '/ouvidoria'
        },
        {
            title: 'Transparência Fiscal',
            description: 'Ferramenta que permite ao cidadão acompanhar receitas e despesas públicas, promovendo clareza sobre a gestão dos recursos financeiros do governo.',
            icon: <InfoIcon sx={{ fontSize: 40, color: config?.color }} />,
            link: '/transparencia'
        }

    ];

    return (
        <Box sx={{ marginTop: 3 }}>
            <TitleSection config={config}
                title="Transparência e Acesso à Informação"
                subtitle="Conheça os principais canais de comunicação e informações disponíveis ao cidadão, promovendo clareza e facilidade no acesso a serviços públicos."
            />
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, flexWrap: { xs: 'wrap', md: 'nowrap' }, p: 2 }}>
                {camara ?
                    cardCamara.map((card, index) => (
                        <Card key={index} sx={{ minWidth: 265, maxWidth: 335, flex: '1 1 0', boxShadow: 3, borderRadius: 3 }}>
                            <CardContent>
                                <Box display="flex" alignItems="center" gap={1} mb={2}>
                                    {card.icon}
                                    <Typography variant="h5" component="div" fontWeight="bold" color="text.primary">
                                        {card.title}
                                    </Typography>
                                </Box>
                                <Typography variant="body2" color="text.secondary">
                                    {card.description}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Link to={card.link} style={{ textDecoration: 'none', width: '100%' }}>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        sx={{
                                            textTransform: 'uppercase',
                                            fontWeight: 'bold',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: 1,
                                            backgroundColor: config?.color,
                                            '&:hover': {
                                                backgroundColor: config?.colorSecondary, color: config?.color
                                            }
                                        }}
                                        endIcon={<OpenInNewIcon />}
                                    >
                                        Consultar
                                    </Button>
                                </Link>
                            </CardActions>
                        </Card>
                    ))

                    : cards.map((card, index) => (
                        <Card key={index} sx={{ minWidth: 265, maxWidth: 335, flex: '1 1 0', boxShadow: 3, borderRadius: 3 }}>
                            <CardContent>
                                <Box display="flex" alignItems="center" gap={1} mb={2}>
                                    {card.icon}
                                    <Typography variant="h5" component="div" fontWeight="bold" color="text.primary">
                                        {card.title}
                                    </Typography>
                                </Box>
                                <Typography variant="body2" color="text.secondary">
                                    {card.description}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Link to={card.link} style={{ textDecoration: 'none', width: '100%' }}>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        sx={{
                                            textTransform: 'uppercase',
                                            fontWeight: 'bold',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: 1,
                                            backgroundColor: config?.color,
                                            '&:hover': {
                                                backgroundColor: config?.colorSecondary, color: config?.color
                                            }
                                        }}
                                        endIcon={<OpenInNewIcon />}
                                    >
                                        Consultar
                                    </Button>
                                </Link>
                            </CardActions>
                        </Card>
                    ))}

            </Box>
        </Box>
    );
}

export default InfoCards;
