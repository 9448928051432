import React, { useEffect, useState } from 'react';
import {
  Box, Typography, Button, Grid, IconButton, Collapse, Divider, useMediaQuery,
  TextField, Pagination, InputAdornment
} from '@mui/material';
import { Notifications, FileDownload, ExpandMore, Search } from '@mui/icons-material';
import { getDatabase, ref, get, query, orderByChild } from 'firebase/database';
import firebaseApp, { KEY_REF } from './adm/admin/firebaseConfig';
import { useTheme } from '@mui/material/styles';
import { getConfig } from './adm/ConfigContext';

function removePTags(html) {
  return html.replace(/<p>/g, '').replace(/<\/p>/g, '').replace(/<ol>.*?<\/ol>/gs, '').replace(/<li>.*?<\/li>/gs, '');
}

function truncateText(text, maxLength) {
  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
}

export default function Avisos() {
  const config = getConfig()
  const [avisos, setAvisos] = useState([]);
  const [filteredAvisos, setFilteredAvisos] = useState([]);
  const [expanded, setExpanded] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const itemsPerPage = 5;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    const fetchAvisos = async () => {
      try {
        const databaseInstance = getDatabase(firebaseApp);
        const avisosRef = query(ref(databaseInstance, `${KEY_REF}/avisos_sociais`), orderByChild('data'));
        const snapshot = await get(avisosRef);

        if (snapshot.exists()) {
          const data = snapshot.val();
          const avisosArray = Object.keys(data).map((key) => ({
            id: key,
            date: data[key].data,
            titulo: data[key].titulo,
            descricao: data[key].descricao,
            numero: data[key].numero,
            horario: data[key].horario,
            modalidade: data[key].modalidade,
            documentoUrl: data[key].documentoUrl,
            ativo: data[key].ativo,
          }));
          const avisosOrdenados = avisosArray.reverse();
          setAvisos(avisosOrdenados);
          setFilteredAvisos(avisosOrdenados);
        } else {
          console.log('No data available');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchAvisos();
  }, []);

  const handleExpandClick = (id) => {
    setExpanded((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);

    const filtered = avisos.filter((item) =>
      item.titulo.toLowerCase().includes(term) ||
      item.numero.toLowerCase().includes(term) ||
      item.descricao.toLowerCase().includes(term) ||
      item.modalidade.toLowerCase().includes(term)
    );

    setFilteredAvisos(filtered);
    setCurrentPage(1);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const renderListItem = (item) => (
    <Box
      key={item.id}
      sx={{
        padding: 2,
        backgroundColor: item.ativo ? '#fff' : '#d3d3d3',
        borderRadius: 2,
        boxShadow: 1,
        marginBottom: 2,
        opacity: item.ativo ? 1 : 0.8,
      }}
    >
      <Grid container spacing={isMobile ? 1 : 2} direction={isMobile ? "column" : "row"} alignItems="center" justifyContent="space-between">
        <Grid item xs={isMobile ? 12 : 5}>
          <Box display="flex" alignItems="center">
            <IconButton color="primary" sx={{ marginRight: 1 }}>
              <Notifications />
            </IconButton>
            <Typography variant={isMobile ? "body1" : "h7"} fontWeight="bold" sx={{ overflowWrap: 'break-word' }}>
              {truncateText(item.titulo, 80)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={isMobile ? 12 : 1}>
          <Typography variant="body2" color="textSecondary" textAlign={isMobile ? 'left' : 'center'}>
            {item.numero}
          </Typography>
        </Grid>
        <Grid item xs={isMobile ? 12 : 2}>
          <Typography variant="body2" color="textSecondary" textAlign={isMobile ? 'left' : 'center'}>
            {item.date}
          </Typography>
        </Grid>
        <Grid item xs={isMobile ? 12 : 3}>
          <Box display="flex" justifyContent={isMobile ? 'space-between' : 'center'}>
            {item.documentoUrl != null || item.documentoUrl != "" && <>


              <Button
                href={item.documentoUrl}
                target="_blank"
                variant="contained"
                size="small"
                startIcon={<FileDownload />}
                sx={{
                  textTransform: 'uppercase',
                  minWidth: '100px',
                  fontSize: '0.875rem',
                  opacity: 1,
                }}
              >
                Baixar
              </Button>

            </>}

            <Button
              variant="outlined"
              size="small"
              onClick={() => handleExpandClick(item.id)}
              endIcon={<ExpandMore />}
              sx={{
                textTransform: 'uppercase',
                minWidth: '100px',
                ml: 1,
                fontSize: '0.875rem',
                opacity: 1,
              }}
            >
              Detalhes
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Collapse in={expanded[item.id]} timeout="auto" unmountOnExit>
        <Box sx={{ padding: 2, mt: 2, backgroundColor: '#f9f9f9', borderRadius: 1 }}>
          {item.horario && <Typography variant="body2"><strong>Informação (1):</strong> {item.horario}</Typography>}
          {item.numero && <Typography variant="body2"><strong>Informação (2):</strong> {item.numero}</Typography>}
          {item.modalidade && <Typography variant="body2"><strong>Categoria:</strong> {item.modalidade}</Typography>}
          <Typography variant="body2" sx={{ mt: 0.5 }}>
            <strong>Descrição:</strong> {removePTags(item.descricao)}
          </Typography>
        </Box>
      </Collapse>
      {!item.ativo && (
        <Typography variant="body2" sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', mt: 1 }}>
          Este aviso foi desabilitado.
        </Typography>
      )}
      <Divider sx={{ marginTop: 2 }} />
    </Box>
  );

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = filteredAvisos.slice(startIndex, startIndex + itemsPerPage);

  return (
    <Box sx={{ padding: 1, paddingLeft: { xs: 3, md: 15 }, paddingRight: { xs: 3, md: 15 } }}>
      <Box sx={{ py: 2, backgroundColor: '#f5f5f5', maxWidth: '1400px', mx: 'auto' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography sx={{ fontSize: 28, color: config?.color, fontWeight: 'bold' }}>
            NOTAS, COMUNICADOS E EVENTOS
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Pesquisar..."
            value={searchTerm}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            sx={{ width: isMobile ? '100%' : '300px' }}
          />
        </Box>
        <Divider sx={{ backgroundColor: config?.color, height: 2, mb: 2 }} />
        {currentItems.length > 0 ? (
          currentItems.map((item) => renderListItem(item))
        ) : (
          <Typography fontSize={19} align="center" sx={{ color: '#888', mt: 2 }}>
            Não há avisos disponíveis.
          </Typography>
        )}
        <Box display="flex" justifyContent="center" mt={3}>
          <Pagination
            count={Math.ceil(filteredAvisos.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
        </Box>
      </Box>
    </Box>
  );
}
