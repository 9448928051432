import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Card, CardMedia, CardContent, Button, IconButton, useMediaQuery, Slide } from '@mui/material';
import { YouTube, ThumbUp, PlayArrow, ThumbDown, ThumbUpAlt, ThumbDownAlt } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

function Videos({config}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [likedVideos, setLikedVideos] = useState({});

  useEffect(() => {
    const savedLikes = JSON.parse(localStorage.getItem('likedVideos')) || {};
    setLikedVideos(savedLikes);
  }, []);

  const toggleLike = (src) => {
    setLikedVideos((prev) => {
      const updated = { ...prev, [src]: prev[src] === true ? null : true };
      localStorage.setItem('likedVideos', JSON.stringify(updated));
      return updated;
    });
  };

  const toggleDislike = (src) => {
    setLikedVideos((prev) => {
      const updated = { ...prev, [src]: prev[src] === false ? null : false };
      localStorage.setItem('likedVideos', JSON.stringify(updated));
      return updated;
    });
  };

  const videoData = [
    {
      src: "https://www.youtube.com/embed/ReMYHoU3RK8",
      title: "A população Gurjaense receberá em breve 50 unidades habitacionais",
      duration: "1min 28s",
    },
    {
      src: "https://www.youtube.com/embed/fzjE_OmFu1o",
      title: "62 Anos de Emancipação Política de Gurjão PB | JAPÃOZIN e LEIDINHA SANTOS",
      duration: "4h 56m 14s",
    },
    {
      src: "https://www.youtube.com/embed/53wHOKfPFh4",
      title: "AQUISIÇÃO DE 02 (DOIS) VEÍCULOS PARA SECRETARIA DE SAÚDE",
      duration: "0m 59s",
    },
    {
      src: "https://www.youtube.com/embed/00SR8lBofww",
      title: "8ª CONFERÊNCIA MUNICIPAL DA ASSISTÊNCIA SOCIAL",
      duration: "3h 3min 20s",
    },
  ];

  const renderVideoCard = (video) => {
    const isLiked = likedVideos[video.src] === true;
    const isDisliked = likedVideos[video.src] === false;
  
    return (
      <Grid item xs={12} sm={6} md={3} key={video.src} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Card sx={{ 
          boxShadow: 3, 
          transition: '0.3s', 
          '&:hover': { boxShadow: 6 }, 
          maxWidth: isMobile ? '100%' : 400, 
          width: '100%' 
        }}>
          <CardMedia
            component="iframe"
            height="220"
            src={video.src}
            title={video.title}
            sx={{ borderRadius: '4px' }}
          />
          <CardContent sx={{ backgroundColor: '#f9f9f9', padding: 2 }}>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 'bold',
                mb: 1,
                textAlign: 'center',
                height: '50px',
                overflow: 'hidden',
                textTransform: 'uppercase',
                fontFamily: 'Poppins, sans-serif',
                fontSize: '1rem',
                letterSpacing: '0.05em',
              }}
            >
              {video.title}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
              <Box>
                <Button
                  startIcon={<PlayArrow />}
                  href={video.src}
                  target="_blank"
                  variant="outlined"
                  size="small"
                  sx={{ mr: 1, color: config?.color }}
                >
                  Visualizar
                </Button>
                {!isLiked && !isDisliked && (
                  <>
                    <IconButton color={config?.color} onClick={() => toggleLike(video.src)}>
                      <ThumbUp />
                    </IconButton>
                    <IconButton color={config?.color} onClick={() => toggleDislike(video.src)}>
                      <ThumbDown sx={{ color: '#1d1d1d' }} />
                    </IconButton>
                  </>
                )}
                {isLiked && (
                  <Slide direction="up" in={isLiked} mountOnEnter unmountOnExit>
                    <Button
                      size="small"
                      startIcon={<ThumbUpAlt sx={{ color: config?.color }} />}
                      variant="outlined"
                      sx={{ fontWeight: 'bold', fontSize: 12, color: config?.color }}
                      onClick={() => toggleLike(video.src)}
                    >
                      Gostei
                    </Button>
                  </Slide>
                )}
                {isDisliked && (
                  <Slide direction="up" in={isDisliked} mountOnEnter unmountOnExit>
                    <Button
                      size="small"
                      startIcon={<ThumbDownAlt sx={{ color: 'red' }} />}
                      variant="outlined"
                      sx={{ fontWeight: 'bold', fontSize: 12 }}
                      onClick={() => toggleDislike(video.src)}
                    >
                      Não Gostei
                    </Button>
                  </Slide>
                )}
              </Box>
              <Typography variant="caption" color="textSecondary">
                {video.duration}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    );
  };
  

  return (
    <Box sx={{ backgroundColor: 'white', padding: 4, paddingLeft: { xs: 3, md: 15 }, paddingRight: { xs: 3, md: 15 }, borderTop: '1px solid #d3d3d3', borderBottom: '1px solid #d3d3d3' }}>
      <Box sx={{ textAlign: 'center', mb: 3 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: config?.color }}>
          <YouTube fontSize="large" sx={{ verticalAlign: 'middle' }} /> #GurjãoProMundo
        </Typography>
      </Box>
      <Grid container spacing={1} justifyContent="center">
        {videoData.map(renderVideoCard)}
      </Grid>
    </Box>
  );
}

export default Videos;
