import React, { useEffect, useState } from 'react';
import './pages_documentos.css';
import { Box } from '@mui/material';
import TitleSection from '../utils/TittleTheme';
import { getDatabase, ref, get } from 'firebase/database';
import DataTable from '../utils/Tabela';
import firebaseApp, { camara, KEY_REF } from '../adm/admin/firebaseConfig';

export default function RGF() {
  const [iframeLoaded, setIframeLoaded] = useState(false);

  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const databaseInstance = getDatabase(firebaseApp);
        const noticiasRef = ref(databaseInstance, `${KEY_REF}/rgf`);

        const snapshot = await get(noticiasRef);
        const data = snapshot.val();

        if (data) {
          const newsArray = Object.keys(data).map((key) => ({
            id: key,
            title: data[key].nome,
            numero: data[key].numero || null,
            mes: data[key].mes,
            dia: data[key].dia || null,
            ano: data[key].ano,
            href: data[key].href
          }));
          setNewsData(newsArray);
        }
      } catch (error) {
        console.error('Erro ao carregar notícias:', error);
      }
    };

    fetchData();
  }, []);

  const handleIframeLoad = () => {
    setIframeLoaded(true);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      {!camara && KEY_REF === 'db/cidades/2506509' ? (<>

        <Box sx={{ padding: 3, maxWidth: '1200px', mx: 'auto' }}>


          <TitleSection title={'RGF'} subtitle={'Relatório que avalia o cumprimento dos limites de despesas com pessoal e endividamento, garantindo a responsabilidade fiscal do município.'} />
          <div className="col-md-12">
            {!iframeLoaded && (
              <div className="loading-overlay">
                <p className="loading-text">Carregando conteudo, por favor aguarde...</p>
              </div>
            )}
            <iframe
              src="https://transparencia.elmartecnologia.com.br/Contab/Relatorios/RGF?Tab=2&isModal=false&ctx=201084"
              style={{ border: '0px solid #ccc', width: '100%', height: '1000px' }}
              title="RGF"
              onLoad={handleIframeLoad}
            ></iframe>
          </div>
        </Box>


      </>) : (<>

        <Box sx={{ padding: 3, maxWidth: '1200px', mx: 'auto' }}>


          <TitleSection title={'RGF'} subtitle={'Relatório que avalia o cumprimento dos limites de despesas com pessoal e endividamento, garantindo a responsabilidade fiscal do município.'} />

          <DataTable data={newsData} />
        </Box>
      </>)
      }

    </>

  );
}
