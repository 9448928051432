export const dataCamara = [
    {
        question: "Licitações",
        answer: "Veja informações sobre Licitações pelo link abaixo.",
        link: "/licitacoes",
        keywords: ["licitacoes", "concorrencia", "pregao", "processo licitatorio", "contratos"]
    },
    {
        question: "Mesa Diretora",
        answer: "Saiba mais sobre a Mesa Diretora pelo link abaixo.",
        link: "/mesadiretora",
        keywords: ["mesa diretora", "presidencia", "camara", "vereadores", "direcao"]
    },
    {
        question: "Diários Oficiais",
        answer: "Consulte os Diários Oficiais pelo link abaixo.",
        link: "/legislacao/diariosoficial",
        keywords: ["diarios oficiais", "publicacoes", "atos", "camara", "documentos"]
    },
    {
        question: "Requerimentos",
        answer: "Acesse os Requerimentos através do link abaixo.",
        link: "/legislacao/requerimentos",
        keywords: ["requerimentos", "pedidos", "documentos", "solicitacoes", "processos"]
    },
    {
        question: "Código Tributário",
        answer: "Consulte o Código Tributário Municipal pelo link abaixo.",
        link: "/legislacao/codigotributario",
        keywords: ["codigo tributario", "tributos", "impostos", "municipal", "legislacao"]
    },
    {
        question: "Projetos de Lei",
        answer: "Veja os Projetos de Lei Municipais no link abaixo.",
        link: "/legislacao/projetodelei",
        keywords: ["projetos de lei", "legislacao", "camara", "leis", "projetos"]
    },
    {
        question: "Lei Orgânica",
        answer: "Acesse a Lei Orgânica Municipal pelo link abaixo.",
        link: "/legislacao/leiorganica",
        keywords: ["lei organica", "legislacao", "municipal", "fundamento legal", "camara"]
    },
    {
        question: "Transparência",
        answer: "Veja informações de transparência no link abaixo.",
        link: "/transparencia",
        keywords: ["transparencia", "financas", "dados publicos", "municipal", "orcamento"]
    },
    {
        question: "Avisos",
        answer: "Confira os avisos mais recentes pelo link abaixo.",
        link: "/avisos",
        keywords: ["avisos", "comunicados", "informacoes", "notificacoes", "municipal"]
    },
    {
        question: "Contratos",
        answer: "Veja os contratos públicos no link abaixo.",
        link: "/arquivos/contratos",
        keywords: ["contratos", "licitacoes", "documentos", "publicos", "acordos"]
    },
    {
        question: "Editais",
        answer: "Acesse os editais pelo link abaixo.",
        link: "/arquivos/editais",
        keywords: ["editais", "documentos", "concorrencia", "processos", "licitacoes"]
    },
    {
        question: "E-SIC",
        answer: "Envie suas solicitações pelo E-SIC através do link abaixo.",
        link: "/e-sic",
        keywords: ["e-sic", "solicitacoes", "informacoes", "acesso publico", "servicos"]
    },
    {
        question: "História",
        answer: "Leia sobre a história da cidade no link abaixo.",
        link: "/historia",
        keywords: ["historia", "municipio", "origem", "cultura"]
    },
    {
        question: "Ouvidoria",
        answer: "Envie sua mensagem pela Ouvidoria no link abaixo.",
        link: "/ouvidoria",
        keywords: ["ouvidoria", "reclamacoes", "sugestoes", "contato", "municipio"]
    },
    {
        question: "Decretos",
        answer: "Veja os decretos municipais no link abaixo.",
        link: "/legislacao/decretos",
        keywords: ["decretos", "legislacao", "municipal", "documentos", "camara"]
    },
    {
        question: "Mocões",
        answer: "Consulte as moções disponíveis pelo link abaixo.",
        link: "/legislacao/mocoes",
        keywords: ["mocoes", "camara", "reconhecimento", "legislacao", "documentos"]
    },
    {
        question: "Leis Municipais",
        answer: "Veja as leis municipais pelo link abaixo.",
        link: "/legislacao/leismunicipais",
        keywords: ["leis municipais", "legislacao", "camara", "documentos", "municipal"]
    },
    {
        question: "Controle Interno",
        answer: "Consulte informações sobre Controle Interno no link abaixo.",
        link: "/legislacao/controleinterno",
        keywords: ["controle interno", "auditoria", "camara", "municipal", "gestao"]
    },
    {
        question: "Tabela de Diárias",
        answer: "Veja a tabela de diárias pelo link abaixo.",
        link: "/arquivos/tabela-diarias",
        keywords: ["tabela de diarias", "gestao", "financas", "documentos", "camara"]
    },
    {
        question: "PCA",
        answer: "Consulte o Plano de Contas Anual no link abaixo.",
        link: "/arquivos/pca",
        keywords: ["pca", "plano de contas", "orcamento", "gestao", "camara"]
    },
    {
        question: "Frota",
        answer: "Veja informações sobre a frota pelo link abaixo.",
        link: "/arquivos/frota",
        keywords: ["frota", "veiculos", "gestao", "municipal", "camara"]
    },
    {
        question: "Quadro de Servidores",
        answer: "Consulte o quadro de servidores no link abaixo.",
        link: "/arquivos/quadro",
        keywords: ["quadro de servidores", "funcionarios", "municipal", "camara", "gestao"]
    }
];

export const prefeitura = [
    {
        question: 'Início',
        answer: 'Esta é a página inicial do portal. Clique no link abaixo para acessar.',
        link: '/',
        keywords: ['inicio', 'home', 'pagina inicial', 'abertura', 'principal'],
    },
    {
        question: "E-SIC",
        answer: "Envie suas solicitações pelo E-SIC através do link abaixo.",
        link: "/e-sic",
        keywords: ["e-sic", "solicitacoes", "informacoes", "acesso publico", "servicos"]
    },
    {
        question: 'Notícias',
        answer: 'Confira as últimas notícias acessando o link abaixo.',
        link: '/noticias/todas',
        keywords: ['noticias', 'novidades', 'informacoes', 'jornal', 'acontecimento'],
    },
    {
        question: 'Notícia',
        answer: 'Confira a notícia completa.',
        link: '/noticias/:titulo/:id',
        keywords: ['noticia', 'informacoes', 'conteudo', 'atualidades'],
    },
    {
        question: 'Transparência',
        answer: 'Confira as informações sobre a transparência da administração pública.',
        link: '/transparencia',
        keywords: ['transparencia', 'governo', 'informacoes publicas', 'administracao'],
    },
    {
        question: 'Avisos',
        answer: 'Acesse os avisos mais recentes da Prefeitura.',
        link: '/avisos',
        keywords: ['avisos', 'comunicados', 'notificacoes', 'anuncios'],
    },
    {
        question: 'Legislação',
        answer: 'Consulte as leis e documentos legais da Prefeitura.',
        link: '/legislacao/leismunicipais',
        keywords: ['legislacao', 'leis', 'requerimentos'],
    },
    {
        question: 'Código Tributário',
        answer: 'Acesse o Código Tributário do Município.',
        link: '/legislacao/codigotributario',
        keywords: ['codigo tributario', 'tributacao', 'impostos', 'financas municipais'],
    },
    {
        question: 'Decretos',
        answer: 'Veja os decretos municipais disponíveis.',
        link: '/legislacao/decretos',
        keywords: ['decretos', 'documentos oficiais', 'regulamentacoes'],
    },
    {
        question: 'Diário Oficial',
        answer: 'Acesse o Diário Oficial do Município.',
        link: '/legislacao/diariosoficial',
        keywords: ['diario oficial', 'publicacoes', 'documentos', 'noticias oficiais'],
    },
    {
        question: 'Requerimentos',
        answer: 'Consulte os requerimentos realizados.',
        link: '/legislacao/requerimentos',
        keywords: ['requerimentos', 'solicitacoes', 'documentos oficiais'],
    },
    {
        question: 'Lei Orgânica',
        answer: 'Acesse a Lei Orgânica do Município.',
        link: '/legislacao/leiorganica',
        keywords: ['lei organica', 'constituição municipal'],
    },
    {
        question: 'Projeto de Lei',
        answer: 'Consulte os projetos de lei em trâmite.',
        link: '/legislacao/projetodelei',
        keywords: ['projeto de lei', 'proposta de lei'],
    },
    {
        question: 'Moções',
        answer: 'Consulte as moções registradas.',
        link: '/legislacao/mocoes',
        keywords: ['mocoes', 'propostas', 'legislacao'],
    },
    {
        question: 'Pedidos de Informação',
        answer: 'Acesse os pedidos de informação realizados.',
        link: '/legislacao/pedidos_info',
        keywords: ['pedidos de informacao', 'solicitacoes', 'documentos'],
    },
    {
        question: 'Leis Municipais',
        answer: 'Consulte as leis municipais em vigor.',
        link: '/legislacao/leismunicipais',
        keywords: ['leis municipais', 'regulamentos', 'documentos legais'],
    },
    {
        question: 'Portarias',
        answer: 'Veja as portarias municipais.',
        link: '/legislacao/portarias',
        keywords: ['portarias', 'documentos administrativos'],
    },
    {
        question: 'Controle Interno',
        answer: 'Acesse as informações sobre o controle interno da Prefeitura.',
        link: '/legislacao/controleinterno',
        keywords: ['controle interno', 'auditoria', 'gestao pública'],
    },
    {
        question: 'Licitacoes',
        answer: 'Consulte as licitações públicas da Prefeitura.',
        link: '/licitacoes',
        keywords: ['licitacoes', 'concursos', 'contratos publicos'],
    },
    {
        question: 'Arquivos Públicos',
        answer: 'Acesse editais, contratos e outros documentos públicos.',
        link: '/arquivos/editais',
        keywords: ['arquivos', 'documentos publicos', 'editais', 'contratos'],
    },
    {
        question: 'Editais',
        answer: 'Consulte os editais de concursos e outros processos.',
        link: '/arquivos/editais',
        keywords: ['editais', 'processos seletivos', 'concursos'],
    },
    {
        question: 'Contratos',
        answer: 'Acesse os contratos públicos celebrados.',
        link: '/arquivos/contratos',
        keywords: ['contratos', 'documentos publicos', 'acordos'],
    },
    {
        question: 'LDO',
        answer: 'Acesse a Lei de Diretrizes Orçamentárias.',
        link: '/arquivos/ldo',
        keywords: ['ldo', 'orçamento', 'financas públicas'],
    },
    {
        question: 'LOA',
        answer: 'Acesse a Lei Orçamentária Anual.',
        link: '/arquivos/loa',
        keywords: ['loa', 'orçamento anual', 'finanças públicas'],
    },
    {
        question: 'RGF',
        answer: 'Consulte o Relatório de Gestão Fiscal.',
        link: '/arquivos/rgf',
        keywords: ['rgf', 'gestao fiscal', 'financas municipais'],
    },
    {
        question: 'Tabela Diárias',
        answer: 'Acesse a tabela de diárias municipais.',
        link: '/arquivos/tabela-diarias',
        keywords: ['tabela diarias', 'diarias', 'despesas publicas'],
    },
    {
        question: 'PCA',
        answer: 'Acesse os planos de ação da Prefeitura.',
        link: '/arquivos/pca',
        keywords: ['pca', 'planos de acao', 'gestao publica'],
    },
    {
        question: 'RREO',
        answer: 'Consulte o Relatório Resumido de Execução Orçamentária.',
        link: '/arquivos/rreo',
        keywords: ['rreo', 'execucao orcamentaria', 'relatorio financeiro'],
    },
    {
        question: 'Frota',
        answer: 'Veja as informações sobre a frota municipal.',
        link: '/arquivos/frota',
        keywords: ['frota', 'veiculos', 'transporte publico'],
    },
    {
        question: 'Quadro de Servidores',
        answer: 'Consulte o quadro de servidores públicos municipais.',
        link: '/arquivos/quadro',
        keywords: ['quadro de servidores', 'funcionarios', 'servidores publicos'],
    },
    {
        question: 'Farmácia Básica',
        answer: 'Acesse informações sobre a farmácia básica da cidade.',
        link: '/arquivos/farmaciabasica',
        keywords: ['farmacia basica', 'saude publica', 'medicamentos'],
    },
    {
        question: 'Secretarias',
        answer: 'Acesse informações das secretarias municipais.',
        link: '/secretarias/financas',
        keywords: ['secretarias', 'financas', 'educacao', 'saude', 'administracao', 'cultura'],
    },
    {
        question: 'Finanças',
        answer: 'Acesse informações sobre a Secretaria de Finanças.',
        link: '/secretarias/financas',
        keywords: ['financas', 'contabilidade', 'tributos', 'orçamento'],
    },
    {
        question: 'Educação',
        answer: 'Acesse informações sobre a Secretaria de Educação.',
        link: '/secretarias/educacao',
        keywords: ['educacao', 'escolas', 'ensino', 'cursos'],
    },
    {
        question: 'Administração',
        answer: 'Acesse informações sobre a Secretaria de Administração.',
        link: '/secretarias/administracao',
        keywords: ['administracao', 'gestao', 'servidores'],
    },
    {
        question: 'Cultura',
        answer: 'Acesse informações sobre a Secretaria de Cultura.',
        link: '/secretarias/cultura',
        keywords: ['cultura', 'arte', 'eventos', 'patrimonio'],
    },
    {
        question: 'Saúde',
        answer: 'Acesse informações sobre a Secretaria de Saúde.',
        link: '/secretarias/saude',
        keywords: ['saude', 'hospitais', 'medicos', 'assistencia'],
    },
    {
        question: 'Meio Ambiente',
        answer: 'Acesse informações sobre a Secretaria de Meio Ambiente.',
        link: '/secretarias/meioambiente',
        keywords: ['meio ambiente', 'ecologia', 'sustentabilidade', 'natureza'],
    },
    {
        question: 'Desenvolvimento Rural',
        answer: 'Acesse informações sobre a Secretaria de Desenvolvimento Rural.',
        link: '/secretarias/desenvolvimentorural',
        keywords: ['desenvolvimento rural', 'agricultura', 'campo'],
    },
    {
        question: 'Assistência Social',
        answer: 'Acesse informações sobre a Secretaria de Assistência Social.',
        link: '/secretarias/assistenciasocial',
        keywords: ['assistencia social', 'beneficios', 'atendimento social'],
    },
    {
        question: 'Recursos Humanos',
        answer: 'Acesse informações sobre o setor de Recursos Humanos.',
        link: '/setor/recursoshumanos',
        keywords: ['recursos humanos', 'contratacoes', 'servidores'],
    },
    {
        question: 'Prefeito',
        answer: 'Informações sobre o Prefeito Municipal.',
        link: '/prefeitura/prefeito',
        keywords: ['prefeito', 'administracao', 'governo local'],
    },
    {
        question: 'Vice-Prefeito',
        answer: 'Informações sobre o Vice-Prefeito Municipal.',
        link: '/prefeitura/viceprefeito',
        keywords: ['vice-prefeito', 'administracao', 'governo local'],
    },
    {
        question: 'LGPD',
        answer: 'Acesso à política de proteção de dados pessoais (LGPD).',
        link: '/prefeitura/lgpd',
        keywords: ['lgpd', 'protecaodedados', 'privacidade'],
    },
    {
        question: 'Carta de Serviço',
        answer: 'Conheça a Carta de Serviço da Prefeitura.',
        link: '/prefeitura/carta-de-servico',
        keywords: ['carta de servico', 'servicos publicos', 'informacoes de servicos'],
    },
    {
        question: 'Ouvidoria',
        answer: 'Acesse a Ouvidoria Municipal para registrar sugestões ou reclamações.',
        link: '/ouvidoria',
        keywords: ['ouvidoria', 'sugestoes', 'reclamacoes', 'atendimento ao publico'],
    },
    {
        question: 'História de Gurjão',
        answer: 'Descubra a história da cidade de Gurjão.',
        link: '/historia',
        keywords: ['historia', 'cidade', 'cultura local'],
    },
    {
        question: 'Galeria de Mídia',
        answer: 'Acesse a galeria de fotos e vídeos da Prefeitura.',
        link: '/galeria',
        keywords: ['galeria', 'fotos', 'videos', 'midia'],
    },
];
