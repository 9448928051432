import React, { useState } from 'react';
import {
    Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TextField, Select, MenuItem, FormControl, InputLabel, Button, Pagination, Grid, Typography
} from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import { getConfig } from '../adm/ConfigContext';

const mesesNumeros = {
    janeiro: 1, fevereiro: 2, marco: 3, abril: 4, maio: 5, junho: 6, julho: 7, agosto: 8, setembro: 9, outubro: 10, novembro: 11, dezembro: 12,
};

const months = [
    { value: '', label: 'Todos' },
    { value: 'janeiro', label: 'Janeiro' },
    { value: 'fevereiro', label: 'Fevereiro' },
    { value: 'marco', label: 'Março' },
    { value: 'abril', label: 'Abril' },
    { value: 'maio', label: 'Maio' },
    { value: 'junho', label: 'Junho' },
    { value: 'julho', label: 'Julho' },
    { value: 'agosto', label: 'Agosto' },
    { value: 'setembro', label: 'Setembro' },
    { value: 'outubro', label: 'Outubro' },
    { value: 'novembro', label: 'Novembro' },
    { value: 'dezembro', label: 'Dezembro' },
];

export default function DataTable({ data }) {
    const config = getConfig();
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedMonth, setSelectedMonth] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedModality, setSelectedModality] = useState('');

    const handleModalityChange = (e) => {
        setSelectedModality(e.target.value);
    };



    const handlePageChange = (_, value) => setCurrentPage(value);
    const handleItemsPerPageChange = (e) => setItemsPerPage(parseInt(e.target.value, 10));
    const handleYearChange = (e) => setSelectedYear(e.target.value);
    const handleMonthChange = (e) => setSelectedMonth(e.target.value);
    const handleSearchChange = (e) => setSearchTerm(e.target.value);

    const uniqueYears = [...new Set(data.map(item => String(item.data?.split('-')[0])))]
        .filter(Boolean)
        .sort((a, b) => b.localeCompare(a));


    const filteredAndSortedData = () => {
        let filteredItems = data.map(item => ({
            ...item,
            ano: item.data ? String(item.data.split('-')[0]) : '',
            mes: item.data ? Object.keys(mesesNumeros).find(m => mesesNumeros[m] === parseInt(item.data.split('-')[1])) : ''
        }));

        filteredItems = filteredItems.filter(item =>
            (!selectedYear || String(item.ano) === String(selectedYear)) &&
            (!selectedMonth || item.mes.toLowerCase() === selectedMonth.toLowerCase()) &&
            (!searchTerm || item.titulo.toLowerCase().includes(searchTerm.toLowerCase())) &&
            (!selectedModality || item.modalidade === selectedModality)
        );

        if (selectedModality) {
            filteredItems = filteredItems.filter((item) => item.modalidade === selectedModality);
        }

        return filteredItems.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    };


    const displayedData = filteredAndSortedData();




    return (
        <Box sx={{ padding: 0.5, maxWidth: '1400px', mx: 'auto' }}>
            <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid item xs={12} sm={3}>
                    <TextField fullWidth label="Buscar por nome" variant="outlined" value={searchTerm} onChange={handleSearchChange} />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel>Itens por página</InputLabel>
                        <Select value={itemsPerPage} onChange={handleItemsPerPageChange} label="Itens por página">
                            {[10, 20, 50, 100, 2000].map(value => <MenuItem key={value} value={value}>{value}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel>Filtrar por ano</InputLabel>
                        <Select value={selectedYear} onChange={handleYearChange} label="Filtrar por ano">
                            <MenuItem value="">Todos os anos</MenuItem>
                            {uniqueYears.map(year => <MenuItem key={year} value={year}>{year}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel>Filtrar por mês</InputLabel>
                        <Select value={selectedMonth} onChange={handleMonthChange} label="Filtrar por mês">
                            {months.map(month => <MenuItem key={month.value} value={month.value}>{month.label}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={3}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel>Filtrar por modalidade</InputLabel>
                        <Select
                            value={selectedModality}
                            onChange={handleModalityChange}
                            label="Filtrar por modalidade"
                        >
                            <MenuItem value="">Todas as modalidades</MenuItem>
                            <MenuItem value="CONCORRÊNCIAS">CONCORRÊNCIAS</MenuItem>
                            <MenuItem value="DISPENSAS">DISPENSAS</MenuItem>
                            <MenuItem value="ADESÃO">ADESÃO</MenuItem>
                            <MenuItem value="INEXIGIBILIDADE">INEXIGIBILIDADE</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

            </Grid>

            {!displayedData.length && <Typography variant="body2" color="textSecondary" align="center" sx={{ mb: 2 }}>Não há itens a exibir</Typography>}

            <TableContainer component={Paper}>
                <Table>
                    <TableHead sx={{ backgroundColor: config?.color }}>
                        <TableRow>
                            <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Nº</TableCell>
                            <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Modalidade</TableCell>
                            <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Descrição</TableCell>

                            {/* {displayedData.some(item => item.mes) && <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Mês</TableCell>} */}
                            {displayedData.some(item => item.ano) && <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Ano</TableCell>}
                            <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Arquivos</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayedData.map(row => (
                            <TableRow key={row.id}>
                                <TableCell>{row.numero?.toUpperCase() || ''}</TableCell>
                                <TableCell>{row?.modalidade || ''}</TableCell>
                                <TableCell>
                                    {row.descricao?.toUpperCase() || ''}
                                </TableCell>
                                {row.ano && <TableCell>{row.ano}</TableCell>}
                                <TableCell sx={{ maxWidth: '280px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    <Box sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: 1,
                                        justifyContent: 'flex-start'
                                    }}>
                                        {row.hrefs.publicidade && (
                                            <Button
                                                size="small"
                                                variant="contained"
                                                href={row.hrefs.publicidade}
                                                target="_blank"
                                                sx={{
                                                    flex: '1 1 45%', // Flexível, ocupa 45% do espaço
                                                    minWidth: '120px', // Largura mínima
                                                    whiteSpace: 'normal', // Permite quebra de linha
                                                    textOverflow: 'ellipsis', // Evita que o texto ultrapasse
                                                    wordBreak: 'break-word', // Quebra palavras longas
                                                }}
                                            >
                                                PUBLICIDADE
                                            </Button>
                                        )}
                                        {row.hrefs.contrato && (
                                            <Button
                                                size="small"
                                                variant="contained"
                                                href={row.hrefs.contrato}
                                                target="_blank"
                                                sx={{
                                                    flex: '1 1 45%',
                                                    minWidth: '120px',
                                                    whiteSpace: 'normal',
                                                    textOverflow: 'ellipsis',
                                                    wordBreak: 'break-word',
                                                }}
                                            >
                                                CONTRATO
                                            </Button>
                                        )}
                                        {row.hrefs.edital && (
                                            <Button
                                                size="small"
                                                variant="contained"
                                                href={row.hrefs.edital}
                                                target="_blank"
                                                sx={{
                                                    flex: '1 1 45%',
                                                    minWidth: '120px',
                                                    whiteSpace: 'normal',
                                                    textOverflow: 'ellipsis',
                                                    wordBreak: 'break-word',
                                                }}
                                            >
                                                EDITAL
                                            </Button>
                                        )}
                                        {row.hrefs.outros && (
                                            <Button
                                                size="small"
                                                variant="contained"
                                                href={row.hrefs.outros}
                                                target="_blank"
                                                sx={{
                                                    flex: '1 1 45%',
                                                    minWidth: '120px',
                                                    whiteSpace: 'normal',
                                                    textOverflow: 'ellipsis',
                                                    wordBreak: 'break-word',
                                                }}
                                            >
                                                OUTROS
                                            </Button>
                                        )}
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}




                    </TableBody>
                </Table>
            </TableContainer>

            <Box display="flex" justifyContent="center" mt={3}>
                <Pagination count={Math.ceil(data.length / itemsPerPage)} page={currentPage} onChange={handlePageChange} />
            </Box>
        </Box>
    );
}
