import React, { useEffect } from 'react'
import './pages_documentos.css';
import TitleSection from '../utils/TittleTheme';
import { Box } from '@mui/material';
import DataTable from '../utils/Tabela';
import { gurjao } from '../adm/admin/firebaseConfig';

export default function Solicitacoes() {


	const documentos = [
		{
			id: 1,
			title: "REQUERIMENTO DE FERIAS",
			href: "/documentos/rh/Requerimento Ferias.docx",
			mes: "novembro",
			ano: 2023
		},
		{
			id: 2,
			title: "LICENÇA MATERNIDADE",
			href: "/documentos/rh/Requerimento Licença Maternidade.docx",
			mes: "novembro",
			ano: 2023
		},
		{
			id: 3,
			title: "LICENÇA PATERNIDADE",
			href: "/documentos/rh/Requerimento Licença Paternidade.docx",
			mes: "novembro",
			ano: 2023
		},
		{
			id: 4,
			title: "LICENÇA SEM VENCIMENTO",
			href: "/documentos/rh/Requerimento Licença Sem Vencimento.docx",
			mes: "novembro",
			ano: 2023
		},
		{
			id: 5,
			title: "LICENÇA PRÊMIO",
			href: "/documentos/rh/Requerimento Licença Prêmio.docx",
			mes: "novembro",
			ano: 2023
		},
		{
			id: 6,
			title: "SALARIO FAMILIA",
			href: "#",
			mes: "novembro",
			ano: 2023
		},
		{ id: 7, title: "TEMPO DE SERVIÇO", href: "/documentos/rh/Requerimento Declaração de tempo de serviço.docx", mes: "novembro", ano: "2023" },
		{ id: 8, title: "ESTATUTO DO SERVIDOR", href: "/documentos/rh/Estatuto do Servidor.pdf", mes: "novembro", ano: "2023" },

	];

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);

	return (

		<Box sx={{ padding: 3, maxWidth: '1200px', mx: 'auto' }}>


			<TitleSection title={'DOCUMENTOS, SOLICITAÇÕES e REQUERIMENTOS'} subtitle={'Pedidos formais relacionados a direitos e benefícios dos servidores municipais, como tempo de serviço, licenças, afastamentos e outros assuntos de Recursos Humanos.'} />

			{gurjao && <DataTable data={documentos} />}
		</Box>



	)
}
