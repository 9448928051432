import React, { useEffect, useState } from 'react';
import {
    Container, Typography, Grid, Box, Paper, TextField, Button,
    Modal, Fade, Backdrop, CircularProgress, Tooltip, Snackbar,
    Alert, IconButton
} from '@mui/material';
import { ThumbUp, ThumbDown, Message } from '@mui/icons-material';
import { getDatabase, ref, push, limitToLast, query, get, update } from 'firebase/database';
import TitleSection from '../utils/TittleTheme';
import SidebarNavigation from '../utils/SideBarNavigation';
import firebaseApp, { KEY_REF } from '../adm/admin/firebaseConfig';
import { getConfig } from '../adm/ConfigContext';

const OuvidoriaPage = () => {
    const config = getConfig()
    const [open, setOpen] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [formData, setFormData] = useState({ name: '', email: '', message: '' });
    const [submissions, setSubmissions] = useState([]);
    const [filter, setFilter] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [timeLeft, setTimeLeft] = useState('');

    const databaseInstance = getDatabase(firebaseApp);
    const ouvidoriaRef = ref(databaseInstance, `${KEY_REF}/ouvidoria`);

    const calculateTimeLeft = () => {
        const lastSent = localStorage.getItem('ouvidoriaLastSent');
        if (!lastSent) return '';
        const lastSentDate = new Date(lastSent);
        const now = new Date();
        const difference = lastSentDate.getTime() + 3 * 24 * 60 * 60 * 1000 - now.getTime();
        return difference <= 0 ? '' : `${Math.floor(difference / (1000 * 60 * 60 * 24))}d ${Math.floor((difference / (1000 * 60 * 60)) % 24)}h ${Math.floor((difference / (1000 * 60)) % 60)}m ${Math.floor((difference / 1000) % 60)}s`;
    };

    const canSubmit = () => calculateTimeLeft() === '';

    useEffect(() => {
        const fetchData = async () => {
            try {
                const messageQuery = query(ouvidoriaRef, limitToLast(10));
                const snapshot = await get(messageQuery);
                const messages = [];
                snapshot.forEach((childSnapshot) => {
                    const data = childSnapshot.val();
                    messages.push({ id: childSnapshot.key, ...data });
                });
                setSubmissions(messages.reverse());
            } catch (error) {
                console.error('Erro ao carregar mensagens:', error);
            }
        };
    
        fetchData();
    
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
    
        return () => clearInterval(timer);
    }, []);
    

    const handleSubmit = async () => {
        if (!canSubmit()) {
            alert('Você só pode enviar uma mensagem a cada 3 dias.');
            return;
        }
        setLoading(true);
        await push(ouvidoriaRef, { ...formData, date: new Date().toLocaleDateString(), likes: 0, dislikes: 0 });
        localStorage.setItem('ouvidoriaLastSent', new Date());
        setSuccess(true);
        setFormData({ name: '', email: '', message: '' });
        setLoading(false);
        setTimeLeft(calculateTimeLeft());
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleOpen = (message) => {
        setSelectedMessage(message);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleLikeDislike = async (id, type) => {
        const userFeedback = JSON.parse(localStorage.getItem('userFeedback')) || {};
        if (userFeedback[id]) return;

        const updatedMessage = submissions.find(sub => sub.id === id);
        updatedMessage[type] = (updatedMessage[type] || 0) + 1;
        await update(ref(databaseInstance, `${KEY_REF}/ouvidoria/${id}`), { [type]: updatedMessage[type] });

        userFeedback[id] = type;
        localStorage.setItem('userFeedback', JSON.stringify(userFeedback));
        setSubmissions((prev) => prev.map(sub => (sub.id === id ? updatedMessage : sub)));
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, []);
    

    return (
        <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 3, py: 5 }}>
            <Box sx={{ flex: 4, display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box sx={{ width: "100%" }}>
                    <TitleSection title={'Ouvidoria Pública '} subtitle={'Envie sua reclamação, sugestão ou elogio para a administração pública.'} />
                </Box>

                <Paper elevation={2} sx={{ p: 3, mb: 3 }}>
                    <Typography variant="h6" gutterBottom>Envie sua mensagem</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth label="Nome" name="name" value={formData.name} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField fullWidth label="Email" name="email" value={formData.email} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth label="Mensagem" name="message" value={formData.message} onChange={handleChange} multiline rows={4} />
                            {timeLeft && (
                                <Typography variant="body2" color="error" sx={{ mb: 2, mt: 2, textAlign: 'center' }}>
                                    Você poderá enviar outra mensagem em: {timeLeft}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            
                            <Tooltip title="Enviar mensagem" open={success} onClose={() => setSuccess(false)} placement="top">
                                <span>
                                    <Button variant="contained" color="primary"   sx={{backgroundColor: config?.color}} onClick={handleSubmit} disabled={loading || !canSubmit()}>
                                        {loading ? 'Enviando...' : 'Enviar'}
                                    </Button>
                                </span>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Paper>

                <Box sx={{ width: "100%" }}>
                    <TitleSection title={'Mensagens - Geral'} share={true} subtitle={'Últimas Mensagens recebidas pela Ouvidoria Municipal'} />
                </Box>

                <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
    <TextField
        fullWidth
        label="Buscar mensagens"
        value={filter}
        onChange={(e) => setFilter(e.target.value.toLowerCase())}
        sx={{ mb: 1 }}
    />
    <Grid container spacing={2}>
        {submissions
            .filter(submission => submission.message.toLowerCase().includes(filter))
            .map((submission) => {
                const userFeedback = JSON.parse(localStorage.getItem('userFeedback') || '{}')[submission.id];
                return (
                    <Grid item xs={12} key={submission.id}>
                        <Paper
                            elevation={1}
                            sx={{
                                p: 1,
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: { xs: 'column', sm: 'row' },
                                gap: 2,
                                width: '100%',
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                <Message />
                                <Box sx={{ maxWidth: '100%' }}>
                                    <Typography variant="subtitle1">Anônimo</Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {submission.date}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {submission.email.replace(/(.{2})(.*)(@.*)/, "$1***$3")}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            wordBreak: 'break-word',
                                            textAlign: 'justify',
                                            mt: { xs: 1, sm: 0 },
                                        }}
                                    >
                                        {window.innerWidth <= 600 ? (
                                            <Box sx={{ mt: 2 }}>
                                                <Tooltip title="Ver mensagem completa">
                                                    <Button
                                                        onClick={() => handleOpen(submission)}
                                                        variant="contained"
                                                        color="primary"
                                                        size="small"
                                                        sx={{
                                                            backgroundColor: 'blue',
                                                            color: 'white',
                                                            textTransform: 'none',
                                                            '&:hover': { backgroundColor: 'darkblue' },
                                                            whiteSpace: 'nowrap',
                                                        }}
                                                    >
                                                        VER MENSAGEM
                                                    </Button>
                                                </Tooltip>
                                            </Box>
                                        ) : (
                                            submission.message.length > 100 ? (
                                                <>
                                                    {submission.message.substring(0, 100)}...
                                                    <Tooltip title="Ler mensagem completa">
                                                        <Button
                                                            onClick={() => handleOpen(submission)}
                                                            variant="text"
                                                            color="primary"
                                                            size="small"
                                                            sx={{ '&:hover': { textDecoration: 'underline' } }}
                                                        >
                                                            Ver mais
                                                        </Button>
                                                    </Tooltip>
                                                </>
                                            ) : (
                                                submission.message
                                            )
                                        )}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                    mt: { xs: 2, sm: 0 },
                                    justifyContent: { xs: 'center', sm: 'flex-start' },
                                }}
                            >
                                <Tooltip title={userFeedback ? "Você já avaliou, e não pode ser alterado." : "Gostei"}>
                                    <span>
                                        <IconButton
                                            onClick={() => handleLikeDislike(submission.id, 'likes')}
                                            disabled={userFeedback}
                                            sx={{ '&:hover': { backgroundColor: userFeedback ? 'inherit' : 'rgba(0, 0, 255, 0.1)' } }}
                                        >
                                            <ThumbUp color="primary" />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <Typography variant="body2">{submission.likes}</Typography>
                                <Tooltip title={userFeedback ? "Você já avaliou, e não pode ser alterado." : "Não gostei"}>
                                    <span>
                                        <IconButton
                                            onClick={() => handleLikeDislike(submission.id, 'dislikes')}
                                            disabled={userFeedback}
                                            sx={{ '&:hover': { backgroundColor: userFeedback ? 'inherit' : 'rgba(255, 0, 0, 0.1)' } }}
                                        >
                                            <ThumbDown color="error" />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <Typography variant="body2">{submission.dislikes}</Typography>
                            </Box>
                        </Paper>
                    </Grid>
                );
            })}
    </Grid>
</Paper>




            </Box>

            <Box sx={{ flex: { xs: 1, md: 1.5 }, minWidth: 250 }} >
            <SidebarNavigation noticia={true} />
            </Box>

            <Modal open={open} onClose={handleClose} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }}>
                <Fade in={open}>
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 2,
                        borderRadius: 2,
                        width: { xs: '90%', sm: '70%', md: '50%' },
                        maxHeight: '80vh',
                        overflowY: 'auto',
                    }}>
                        {selectedMessage && (
                            <>
                                <Typography variant="h6" gutterBottom>Anônimo</Typography>
                                <Typography variant="body2" color="textSecondary" gutterBottom>{selectedMessage.date}</Typography>
                                <Typography variant="body1" sx={{ mt: 2, textAlign: 'justify' }}>{selectedMessage.message}</Typography>
                            </>
                        )}
                    </Box>
                </Fade>
            </Modal>


            <Snackbar open={success} autoHideDuration={4000} onClose={() => setSuccess(false)}>
                <Alert onClose={() => setSuccess(false)} severity="success" sx={{ width: '100%' }}>
                    Mensagem enviada com sucesso! Você só poderá enviar outra mensagem em 3 dias.
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default OuvidoriaPage;
