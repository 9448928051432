import { getAuth } from 'firebase/auth';
import { getDatabase, ref } from 'firebase/database';
import { getStorage } from "firebase/storage";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import "firebase/storage"
import { dataCamara, prefeitura } from '../../utils/Data';


const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

export const auth = getAuth(firebaseApp);
export const database = getDatabase(firebaseApp);
export const storage = getStorage(firebaseApp);

// CONFIG - REMIGIO - PB
// export const cidade = 'Remígio - PB'
// export const KEY_REF = 'db/cidades/25127051'
// export const KEY_LINK = 'https://camaraderemigio.pb.gov.br/'
// export const camara = true;
// export const dataPesquisa = dataCamara


// CONFIG - CAMARA DE GURJAO - PB
export const cidade = 'Gurjão - PB'
export const KEY_REF = 'db/cidades/25065091'
export const KEY_LINK = 'https://camaradegurjao.pb.gov.br/'
export const camara = true;
export const dataPesquisa = dataCamara

// CONFIG - AMPARO - PB
// export const cidade = 'Amparo - PB'
// export const KEY_REF = 'db/cidades/2500734'
// export const KEY_LINK = 'https://amparo.pb.gov.br/'
// export const camara = false;
// export const dataPesquisa = prefeitura

// CONFIG - GURJAO - PB
// export const cidade = 'Gurjão - PB'
// export const KEY_REF = 'db/cidades/2506509'
// export const KEY_LINK = 'https://gurjao.pb.gov.br/'
// export const camara = false;
// export const dataPesquisa = prefeitura

// CONFIG - SÃO JOAO DO TIGRE - PB
// export const cidade = 'São Joao do Tigre - PB'
// export const KEY_REF = 'db/cidades/2514107'
// export const KEY_LINK = 'https://saojoaodotigre.pb.gov.br/'
// export const camara = false;
// export const dataPesquisa = prefeitura


// CONFIG - CAMARA DE ALCANTIL - PB
// export const cidade = 'Alcantil - PB'
// export const KEY_REF = 'db/cidades/25005361'
// export const KEY_LINK = 'https://www.camaramunicipaldealcantil.pb.gov.br/'
// export const camara = true;
// export const dataPesquisa = dataCamara

// CONFIG - COXIXOLA - PB
// export const cidade = 'Coxixola - PB'
// export const KEY_REF = 'db/cidades/2504850'
// export const KEY_LINK = 'https://coxixola.pb.gov.br/'
// export const camara = false;
// export const dataPesquisa = prefeitura

export const gurjao = false;

export default firebaseApp;