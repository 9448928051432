let exportedConfig = null;

// Função para receber o config vindo do App.js
export const passConfigToOtherComponent = (config) => {
  exportedConfig = config; // Armazena o valor do config
};

// Exporta o config como constante
export const getConfig = () => {
  if (!exportedConfig) {
    throw new Error(
      "Config ainda não foi definido. Certifique-se de que o App carregou o config corretamente."
    );
  }
  return exportedConfig; // Retorna o valor armazenado
};
