import React, { useState, useEffect } from 'react';
import {
  Box
} from '@mui/material';
import { getDatabase, ref, get } from 'firebase/database';
import firebaseApp, { KEY_LINK, KEY_REF } from '../adm/admin/firebaseConfig';
import TitleSection from '../utils/TittleTheme';
import DataTable from '../utils/Tabela';


export default function LeisMunicipais() {
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
        try {
            const databaseInstance = getDatabase(firebaseApp);
            const noticiasRef = ref(databaseInstance, `${KEY_REF}/leismunicipais`);

            const snapshot = await get(noticiasRef);
            const data = snapshot.val();

            if (data) {
                const newsArray = Object.keys(data).map((key) => ({
                  id: key,
                  title: data[key].nome,
                  numero: data[key].numero || null,
                  mes: data[key].mes,
                  dia: data[key].dia || null,
                  ano: data[key].ano,
                  href: data[key].href
                }));
                setNewsData(newsArray);
            }
        } catch (error) {
            console.error('Erro ao carregar notícias:', error);
        }
    };

    fetchData();
}, []);




  return (
    <Box sx={{ padding: 3, maxWidth: '1200px', mx: 'auto' }}>


      <TitleSection title={'LEIS MUNICIPAIS'}  subtitle={'Normas legais aprovadas pela Câmara Municipal que regulam direitos, deveres e serviços no âmbito do município.'}/>

      <DataTable data={newsData} />
    </Box>
  );
}
