import React, { useEffect, useState } from 'react';
import Noticias from "./Noticias";
import Videos from "./Videos";
import LayoutBanner from "./LayoutBanner";
import Novidades from './Novidades';
import { Box } from '@mui/material';
import LeisMunicipais from './InicioLeisMunicipais';
import TitleSection from './utils/TittleTheme';
import RandomNoticias from './utils/RamdomNoticias';
import InfoCards from './utils/infoCard';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { camara, cidade, KEY_REF } from './adm/admin/firebaseConfig';
import Vereadores from './legislacao/VereadoresGrid';


export default function Inicio({ config }) {



    return (
        <>

            <HelmetProvider>
                <Helmet>
                    {camara ? (<>
                        <title>{`Inicio - Camara Municipal ${cidade}`}</title>
                    </>) : (<>
                        <title>{`Inicio - ${cidade}`}</title>
                    </>)}


                </Helmet>
            </HelmetProvider>

            <Noticias config={config} />
            {!camara && KEY_REF === 'db/cidades/2506509' && <Videos config={config} />}
            {config?.bannerDestaque && <LayoutBanner link="#" src={config?.bannerDestaque} />}
            <InfoCards config={config} />
            {!camara && KEY_REF === 'db/cidades/2506509' && <LayoutBanner link="https://play.google.com/store/apps/details?id=com.bigstarinformatica.gurjaoplus&hl=en_US" src="/imagens/gurjaoplus.png" />}
            <Novidades config={config} />
            {!camara && KEY_REF === 'db/cidades/2506509' && <LayoutBanner link="/arquivos/farmaciabasica" src="/imagens/farmacia.png" />}

            {camara && <Vereadores />}

            <TitleSection config={config} title={'NOTÍCIAS MAIS LIDAS'} subtitle={'Você está vendo as notícias mais lidas de todo o nosso portal'} link={'/noticias/todas'} todos={true} share={true} />
            <Box sx={{ maxWidth: 1400, margin: '0 auto', padding: '0 16px' }}>
                <RandomNoticias config={config} quantidade={6} />
            </Box>

            {!camara && <LayoutBanner link="/legislacao/aldir_blanc" src="/imagens/aldirblanc.png" />}

            <LeisMunicipais config={config} />

            {!camara && <LayoutBanner link="#" src="/imagens/bannergov1.gif" />}




        </>
    );
}
