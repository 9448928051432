import React, { useState } from 'react';
import { Box, Typography, Button, Divider, Snackbar, IconButton, Popover, Grow, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ShareIcon from '@mui/icons-material/Share';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link } from 'react-router-dom';
import DensitySmallIcon from '@mui/icons-material/DensitySmall';
import { getConfig } from '../adm/ConfigContext';

export default function TitleSection({title, subtitle, share, novo, todos, link, transparent }) {
  const config = getConfig();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleShareClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleCopyLink = () => {
    const currentUrl = window.location.href;
    navigator.clipboard.writeText(currentUrl)
      .then(() => setSnackbarOpen(true))
      .catch((err) => console.error('Erro ao copiar o link: ', err));
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'share-popover' : undefined;

  return (
    <Box sx={{ py: 1, backgroundColor: transparent ? 'transparent' : '#f5f5f5', maxWidth: '1400px', mx: 'auto' }}>
      <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems="center" justifyContent={isMobile ? 'center' : 'space-between'} mb={1} px={isMobile ? 2 : 0} ml={isMobile ? 0 : 2} mr={isMobile ? 0 : 2}>

        <Box display="flex" alignItems="center" justifyContent={isMobile ? 'center' : 'flex-start'} sx={{ textAlign: isMobile ? 'center' : 'left' }}>
          <Typography sx={{ fontSize: isMobile ? 22 : 28, color: config?.color, fontWeight: 'bold' }}>
            {title}
          </Typography>

          {novo && (
            <Typography sx={{ fontSize: 12, color: '#00ba12', fontWeight: 'bold', ml: 1 }}>
              NOVO
            </Typography>
          )}
        </Box>

        {!share && (
          <Box mt={isMobile ? 2 : 0} display="flex" justifyContent={isMobile ? 'center' : 'flex-end'} width={isMobile ? '100%' : 'auto'}>
            <Button
              variant="contained"
              size="medium"
              onClick={handleShareClick}
              startIcon={<ShareIcon />}
              sx={{
                backgroundColor: config?.color,
                color: '#FFFFFF',
                textTransform: 'uppercase',
                '&:hover': { backgroundColor: '#1976D2' },
                padding: '8px 20px',
                borderRadius: '30px',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                fontWeight: 'bold',
                letterSpacing: '0.5px',
              }}
            >
              Compartilhar
            </Button>
          </Box>
        )}

        {todos && (
          <Box mt={isMobile ? 2 : 0} display="flex" justifyContent={isMobile ? 'center' : 'flex-end'} width={isMobile ? '100%' : 'auto'}>
            <Link to={link}>
              <Button
                variant="contained"
                size="medium"
                startIcon={<DensitySmallIcon />}
                sx={{
                  backgroundColor: config?.color,
                  color: '#FFFFFF',
                  textTransform: 'uppercase',
                  '&:hover': { backgroundColor: '#1976D2' },
                  padding: '8px 20px',
                  borderRadius: '30px',
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                  fontWeight: 'bold',
                  letterSpacing: '0.5px',
                }}
              >
                Exibir todas
              </Button>
            </Link>
          </Box>
        )}


      </Box>

      {subtitle && (
        <Typography sx={{ fontSize: '0.875rem', color: '#6c757d', mb: 2, textAlign: isMobile ? 'center' : 'left', px: 2 }}>
          {subtitle}
        </Typography>
      )}
      <Divider sx={{ backgroundColor: config?.color, height: 2, mb: 0 }} />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        TransitionComponent={Grow}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          borderRadius: '15px',
          p: 1,
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
          '& .MuiPopover-paper': {
            borderRadius: '15px',
            transformOrigin: 'top center',
          },
        }}
      >
        <Box display="flex" gap={2} p={1}>
          <IconButton onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`, '_blank')}>
            <FacebookIcon sx={{ color: '#1877F2', fontSize: 32 }} />
          </IconButton>
          <IconButton onClick={() => window.open(`https://twitter.com/share?url=${window.location.href}`, '_blank')}>
            <TwitterIcon sx={{ color: '#1DA1F2', fontSize: 32 }} />
          </IconButton>
          <IconButton onClick={() => window.open(`https://wa.me/?text=${window.location.href}`, '_blank')}>
            <WhatsAppIcon sx={{ color: '#25D366', fontSize: 32 }} />
          </IconButton>
          <IconButton onClick={() => window.open(`https://www.linkedin.com/shareArticle?url=${window.location.href}`, '_blank')}>
            <LinkedInIcon sx={{ color: '#0077B5', fontSize: 32 }} />
          </IconButton>
          <IconButton onClick={handleCopyLink}>
            <ContentCopyIcon sx={{ color: '#6c757d', fontSize: 32 }} />
          </IconButton>
        </Box>
      </Popover>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="Link copiado para a área de transferência!"
        action={
          <IconButton size="small" color="inherit" onClick={handleSnackbarClose}>
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        }
      />
    </Box>
  );
}
