import React, { useState, useEffect } from 'react';
import {
	Box
} from '@mui/material';
import { getDatabase, ref, get } from 'firebase/database';
import firebaseApp, { gurjao, KEY_REF } from './adm/admin/firebaseConfig';
import TitleSection from './utils/TittleTheme';
import DataTable from './utils/Tabela';


export default function LeiPauloGustavo() {
	const [newsData, setNewsData] = useState([]);
	const documents = [
		{ id: 1, title: "Publicação do Edital N° 001 02 e 03-2023 Audiovisual Lei Paulo Gustavo Corrigido - 07-12-2023", href: "/documentos/diariooficial/Publicação do Edital N° 001 02 e 03-2023 Audiovisual Lei Paulo Gustavo Corrigido - 07-12-2023.pdf", mes: "dezembro", ano: "2023" },
		{ id: 2, title: "Publicação dos Editais N° 001-2023  e  Premio Produção Nº 002-2023 e Edital de Chamanento Publico  003-2023", href: "/documentos/Publicação dos Editais   N° 001-2023  e  Premio Produção Nº 002-2023 e Edital de Chamanento Publico  003-2023- 07-11-2023.pdf", mes: "novembro", ano: "2023" },
		{ id: 3, title: "ANEXO III - Plano de Trabalho e Planilha Orçamentaria", href: "/documentos/ANEXO III Plano de Trabalho e Planilha Orçamentaria EDITAL 001.pdf", mes: "novembro", ano: "2023" },
		{ id: 4, title: "EDITAL 003 - INSTRUTORES EM AUDIOVISUAL", href: "/documentos/EDITAL 003 CHAMAMENTO PUBLICO DE INSTRUTORES EM AUDIOVISUAL.pdf", mes: "novembro", ano: "2023" },
		{ id: 5, title: "FORMULÁRIO - EDITAL Nº 003 - INSTRUTORES EM AUDIOVISUAL", href: "/documentos/FORMULÁRIO DE INSCRIÇÃO EDITAL Nº 003 CHAMAMENTO PUBLICO INSTRUTORES EM AUDIOVISUAL.pdf", mes: "novembro", ano: "2023" },
	];


	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);

	useEffect(() => { 
		const fetchData = async () => {
			try {
				const databaseInstance = getDatabase(firebaseApp);
				const noticiasRef = ref(databaseInstance, `${KEY_REF}/leipaulogustavo`);

				const snapshot = await get(noticiasRef);
				const data = snapshot.val();

				if (data) {
					const newsArray = Object.keys(data).map((key) => ({
						id: key,
						title: data[key].nome,
						mes: data[key].mes,
						ano: data[key].ano,
						href: data[key].href,
					}));
					setNewsData(newsArray);
				}
			} catch (error) {
				console.error('Erro ao carregar notícias:', error);
			}
		};

		fetchData();
	}, []);




	return (
		<Box sx={{ padding: 3, maxWidth: '1200px', mx: 'auto' }}>


			<TitleSection title={'LEI PAULO GUSTAVO Nº 195/2022'} subtitle={'A Lei Paulo Gustavo (Lei Complementar nº 195/2022) é uma lei federal que visa apoiar o setor cultural brasileiro, que foi afetado pela pandemia de COVID-19. A lei foi criada em homenagem ao ator Paulo Gustavo, que faleceu devido à doença.'} />
			{gurjao ? <DataTable data={documents} /> : <DataTable data={newsData} />}

		</Box>
	);
}
