import React, { useEffect, useState } from 'react';
import SecretaryCard from '../utils/SecretaryCard';
import firebaseApp, { KEY_REF } from '../adm/admin/firebaseConfig';
import { getDatabase, ref, get } from 'firebase/database';

export default function Educacao() {
  const [secretario, setSecretario] = useState(null);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const secretarioDefault = {
    nome: 'Não informado',
    descricao: "",
    foto: 'https://t4.ftcdn.net/jpg/02/15/84/43/360_F_215844325_ttX9YiIIyeaR7Ne6EaLLjMAmy4GvPC69.jpg',
    telefone: "(83) 0000-0000",
    email: "municipiogurjaopb@gmail.com",
    horario: "08h às 14h - Segunda a Sexta-Feira",
    cargo: "Secretario(a) de Educação",
    page: "Sec. de Educação"
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const databaseInstance = getDatabase(firebaseApp);
        const secretariasRef = ref(databaseInstance, `${KEY_REF}/secretarias`);

        const snapshot = await get(secretariasRef);
        const data = snapshot.val();

        if (data) {
          const admin = Object.values(data).find(
            (item) => item.cargo === 'Secretario(a) de Educação'
          );
          setSecretario(admin || secretarioDefault);
        }
      } catch (error) {
        console.error('Erro ao carregar secretários:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {secretario ? (
        <SecretaryCard {...secretario} />
      ) : (
        <p>Aguarde...</p>
      )}
    </div>
  );
}