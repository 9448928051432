import React, { useState, useEffect } from 'react';
import './pages_documentos.css';
import { getDatabase, ref, get } from 'firebase/database';
import firebaseApp, { KEY_LINK, KEY_REF } from '../adm/admin/firebaseConfig';
import { TextField, Select, MenuItem, FormControl, InputLabel, Grid, Box } from '@mui/material';
import TitleSection from '../utils/TittleTheme';
import DataTable from '../utils/Tabela';

const mesesNumeros = {
  janeiro: 1,
  fevereiro: 2,
  marco: 3,
  abril: 4,
  maio: 5,
  junho: 6,
  julho: 7,
  agosto: 8,
  setembro: 9,
  outubro: 10,
  novembro: 11,
  dezembro: 12,
};

export default function Semanario() {
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const databaseInstance = getDatabase(firebaseApp);
        const noticiasRef = ref(databaseInstance, `${KEY_REF}/semanario`);

        const snapshot = await get(noticiasRef);
        const data = snapshot.val();

        if (data) {
          const newsArray = Object.keys(data).map((key) => ({
            id: key,
            title: data[key].nome,
            numero: data[key].numero || null,
            mes: data[key].mes,
            dia: data[key].dia || null,
            ano: data[key].ano,
            href: data[key].href
          }));
          setNewsData(newsArray);
        }
      } catch (error) {
        console.error('Erro ao carregar notícias:', error);
      }
    };

    fetchData();
  }, []);


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);


  return (
    <Box sx={{ padding: 3, maxWidth: '1200px', mx: 'auto' }}>


      <TitleSection title={'Semanário'} subtitle={'Publicações oficiais com atos, editais e informações sobre a administração pública municipal.'} />

      <DataTable data={newsData} />
    </Box>
  );
}
