import React, { useEffect, useState } from 'react';
import { Container, Typography, Grid, Box, Paper, Modal, Fade, Backdrop } from '@mui/material';
import TitleSection from '../utils/TittleTheme';
import SidebarNavigation from '../utils/SideBarNavigation';
import { camara, cidade, KEY_REF } from '../adm/admin/firebaseConfig';

const HistoriaGurjaoPage = ({ config }) => {
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");

    const handleOpen = (image) => {
        setSelectedImage(image);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 3, py: 5 }}>

            {/* Main Content Area */}
            <Box sx={{ flex: 4, display: 'flex', flexDirection: 'column', gap: 2 }}>

                {/* Title Section */}
                <Box sx={{ width: "100%" }}>
                    <TitleSection title={`História de ${cidade}`} subtitle={config.resumoHistorico} />
                </Box>

                <Paper elevation={2} sx={{ p: 3, mb: 3 }}>

                    <Typography paragraph>

                        <div
                            dangerouslySetInnerHTML={{ __html: config.historia }}
                        />
                    </Typography>
                </Paper>


               {!camara && KEY_REF === 'db/cidades/2506509' && (<>
                    <Box sx={{ width: "100%" }}>
                        <TitleSection title={'Galeria de fotos'} subtitle={''} />
                    </Box>

                    {/* Image Mosaic */}
                    <Grid container spacing={2}>
                        {[
                            "/imagens/historia/historia1.jpg",
                            "/imagens/historia/historia2.jpg",
                            "/imagens/historia/historia19.png",
                            "/imagens/historia/historia3.jpeg",
                            "/imagens/historia/historia4.jpg",
                            "/imagens/historia/historia5.jpeg",
                            "/imagens/historia/historia6.jpg",
                            "/imagens/historia/historia7.jpg",
                            "/imagens/historia/historia8.jpg",
                            "/imagens/historia/historia9.jpg",
                            "/imagens/historia/historia16.png",
                            "/imagens/historia/historia10.jpg",
                            "/imagens/historia/historia11.png",
                            "/imagens/historia/historia12.png",
                            "/imagens/historia/historia13.png",
                            "/imagens/historia/historia14.png",
                            "/imagens/historia/historia15.png",
                            "/imagens/historia/historia18.png",
                            "/imagens/historia/historia17.png",

                        ].map((src, index) => (
                            <Grid
                                item
                                xs={index % 4 === 0 ? 12 : 6}  // Alternando tamanhos para criar efeito de mosaico
                                sm={index % 4 === 0 ? 8 : 4}   // Tamanho médio para criar padrão
                                md={index % 4 === 0 ? 6 : 3}   // Tamanho maior em desktops
                                key={index}
                            >
                                <Box
                                    component="img"
                                    src={src}
                                    alt={`Foto ${index + 1}`}
                                    onClick={() => handleOpen(src)}
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        borderRadius: 2,
                                        boxShadow: 2,
                                        transition: '0.3s',
                                        cursor: 'pointer',
                                        '&:hover': { transform: 'scale(1.05)' },
                                    }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </>)}


            </Box>


            <Box sx={{ flex: { xs: 1, md: 1.5 }, minWidth: 250 }} marginTop={5}>
                <SidebarNavigation noticia={true} />
            </Box>


            {/* Modal for Enlarged Image */}
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 2,
                        borderRadius: 2,
                        maxWidth: '90%',
                        maxHeight: '90%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Box
                            component="img"
                            src={selectedImage}
                            alt="Imagem Ampliada"
                            sx={{
                                width: '100%',
                                height: 'auto',
                                maxHeight: '80vh',
                                objectFit: 'contain'
                            }}
                        />
                    </Box>
                </Fade>
            </Modal>
        </Container>
    );
};

export default HistoriaGurjaoPage;
